console.log('How Are You ?');
var nowTemp = new Date();
var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);
var checkin = $('.dpd1').fdatepicker({
    format:'yyyy/mm/dd',
    setDate: new Date(),
    // onRender: function(date) {
    //     return date.valueOf() < now.valueOf() ? 'disabled' : '';
    // }
}).on('changeDate', function(ev) {
    if (ev.date.valueOf() > checkout.date.valueOf()) {
        var newDate = new Date(ev.date)
        newDate.setDate(newDate.getDate() + 1);
        checkout.update(newDate);
    }
    checkin.hide();
    $('.dpd2')[0].focus();
}).data('datepicker');
var checkout = $('.dpd2').fdatepicker({
    format:'yyyy/mm/dd',
    onRender: function(date) {
        return date.valueOf() <= checkin.date.valueOf() ? 'disabled' : '';
    }
}).on('changeDate', function(ev) {
    checkout.hide();
}).data('datepicker');
checkin.setDate(now);

var nextDate = new Date(now)
nextDate.setDate(nextDate.getDate() + 1);
checkout.update(nextDate);

var winW, winH;
// JavaScript Document
$(function() {
    // bootstrap selectpicker
    if (typeof $.fn.selectpicker === "function") {
        $('.selectpicker').selectpicker();
    }
    if (typeof $.fn.bxSlider === "function") {
        $('.bxslider').bxSlider({ pager: false, controls: true, slideWidth: 276 });
    }
    if (typeof $.fn.fancybox === "function") {
        $(".fancybox").fancybox({
            openEffect: 'none',
            closeEffect: 'none',
            border: 0,
            margin: 0,
            padding: 0
        });
    }



    $(window).resize(function() {
        winW = $(this).width();
        winH = $(this).height();
        $('#loginContainer').css({ width: winW, height: winH });
    }).trigger('resize');
    //$('#datetimepicker').datetimepicker({pickTime: false, language: 'tr'}).data("DateTimePicker").getDate();
    //$('#datetimepicker2').datetimepicker({pickTime: false, language: 'tr'}).data("DateTimePicker").getDate();

    $('.openTopMenu').click(function() {
        $(this).hide();
        $('.closeTopMenu').show();
        $('#topMenu > ul').addClass('visible');
    });
    $('.search a').click(function() {
        $('#searchContainer').slideDown();
    });
    $('#headerContainer').mouseleave(function() {
        $('#searchContainer').slideUp();
    });
    $('.closeTopMenu').click(function() {
        $(this).hide();
        $('.openTopMenu').show();
        $('#topMenu > ul').removeClass('visible');
    });
});


// Reservation


function datediff(fromDate, toDate, interval) {
    /** DateFormat month/day/year hh:mm:ss
     * datediff('01/01/2011 12:00:00','01/01/2011 13:30:00','seconds');
     */
    var second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24,
        week = day * 7;
    fromDate = new Date(fromDate);
    toDate = new Date(toDate);
    var timediff = toDate - fromDate;
    if (isNaN(timediff)) return NaN;
    switch (interval) {
        case "years":
            return toDate.getFullYear() - fromDate.getFullYear();
        case "months":
            return (
                (toDate.getFullYear() * 12 + toDate.getMonth()) -
                (fromDate.getFullYear() * 12 + fromDate.getMonth())
            );
        case "weeks":
            return Math.floor(timediff / week);
        case "days":
            return Math.floor(timediff / day);
        case "hours":
            return Math.floor(timediff / hour);
        case "minutes":
            return Math.floor(timediff / minute);
        case "seconds":
            return Math.floor(timediff / second);
        default:
            return undefined;
    }
}


$(document).on('submit', 'form#reservation-form', function() {
    console.log('...')
    var checkin = $('.dpd1').fdatepicker().val();
    var checkout = $('.dpd2').fdatepicker().val();
    var adult = $("#adults").selectpicker().val();
    var child = $("#child").selectpicker().val();
    var promo = $("#promo_code").val();

    // console.log(checkin + '--' + checkout + '--' + adult + '--' + child + '--' + promo);
    window.location = '/reservation?' +
        '&ci=' + checkin +
        '&ng=' + datediff(checkin, checkout, 'days') +
        '&ad=' + adult +
        '&ch=' + child +
        '&pc=' + promo_code;
    return false;
});